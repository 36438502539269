import './App.css';
import * as echarts from 'echarts';
import { ConfigProvider,theme } from 'antd';
import { Route, Routes, RouterProvider, createRoutesFromElements, createBrowserRouter, Outlet } from 'react-router-dom'
import Base from './Paginas/Base';
import Login from './Paginas/Login';
import Inicio from './Paginas/Inicio';
import ValidarSesion from './Componentes/ValidarSesion';
import AtletaListado from './Paginas/Atletas/Listado';
import CmjGuardar from './Paginas/Prueba/CMJ/Guardar';
import NordicGuardar from './Paginas/Prueba/Nordic/Guardar';
import AtletaGuardar from './Paginas/Atletas/Guardar';
import InstitucionListado from './Paginas/Institucion/Listado';
import InstitucionGuardar from './Paginas/Institucion/Guardar';
import PruebaListado from './Paginas/Prueba/Listado';
import PruebaDetalle from './Paginas/Prueba/Detalle';
import MuestraDetalle from './Paginas/Prueba/Muestras';
import BleProveedor from './Contexts/Ble/Proveedor';
import AtletaTendencia from './Paginas/Atletas/Tendencia';
import {transform} from 'echarts-stat';
import PruebaCuadranteDetalle from './Paginas/Prueba/Cuadrante';
import PruebaRankingDetalle from './Paginas/Prueba/Ranking';


echarts.registerTransform(transform.regression);
echarts.registerTheme('dark2', {
  backgroundColor: '#000000',
  contrastColor: 'white'
});


function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Base />,
      children:[{
        path:'/login',
        element:<Login/>
      },
    {
      path:'/',
      element:<ValidarSesion><Inicio/></ValidarSesion>
    },
    {
      path:'/atletas',
      element:<ValidarSesion><AtletaListado/></ValidarSesion>
    },
    {
      path:'/atleta/guardar',
      element:<ValidarSesion><AtletaGuardar/></ValidarSesion>
    },
    {
      path:'/atleta/:id/actualizar',
      element:<ValidarSesion><AtletaGuardar/></ValidarSesion>
    },
    // {
    //   path:'/atleta/:id/borrar',
    //   element:<ValidarSesion><AtletaBorrar/></ValidarSesion>
    // },
    {
      path:'/atleta/:id/iniciar-prueba/cmj',
      element:<ValidarSesion><BleProveedor><CmjGuardar/></BleProveedor></ValidarSesion>
    },
    {
      path:'/atleta/:id/iniciar-prueba/nordic',
      element:<ValidarSesion><BleProveedor><NordicGuardar/></BleProveedor></ValidarSesion>
    },
    {
      path:'/atleta/:id/tendencias',
      element:<ValidarSesion><AtletaTendencia/></ValidarSesion>
    },
    {
      path:'/instituciones',
      element:<ValidarSesion><InstitucionListado/></ValidarSesion>
    },
    {
      path:'/institucion/guardar',
      element:<ValidarSesion><InstitucionGuardar/></ValidarSesion>
    },
    {
      path:'/institucion/:id/actualizar',
      element:<ValidarSesion><InstitucionGuardar/></ValidarSesion>
    },
    {
      path:'/pruebas',
      element:<ValidarSesion><PruebaListado/></ValidarSesion>
    },{
      path:'/prueba/:id',
      element:<ValidarSesion><PruebaDetalle/></ValidarSesion>
    },
    {
      path:'/prueba/cuadrante/:id',
      element:<ValidarSesion><PruebaCuadranteDetalle/></ValidarSesion>
    },
    {
      path:'/prueba/ranking/:id',
      element:<ValidarSesion><PruebaRankingDetalle/></ValidarSesion>
    },
    {
      path:'/muestra/:id',
      element:<ValidarSesion><MuestraDetalle/></ValidarSesion>
    },
    
  ]
    },

  ])

  return (
    <ConfigProvider theme={{ 
      algorithm: theme.darkAlgorithm,
      token:{
        colorPrimary:'#faad14'
      },
      components:{
        Segmented:{
          colorText:'#faad14'
        }
      }
     }}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
