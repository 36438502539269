import { Button, Flex } from "antd";
import { Link } from "react-router-dom";
import CrearGrupoModal from "./Modal";
import { useState } from "react";

const CrearGrupo = ({ registros, isMuestra }) => {
    const [abrir, setAbrir] = useState(false);

    if (!registros?.length) {
        return null;
    }

    const extraParam = isMuestra ? "muestra" : "prueba";
    const links = [
        { path: `/prueba/cuadrante/${registros}?extraParam=${extraParam}`, label: "Reporte de Cuadrante" },
        { path: `/prueba/ranking/${registros}?extraParam=${extraParam}`, label: "Ranking" },
    ];

    return (
        <>
            <CrearGrupoModal abrir={abrir} cerrar={setAbrir} registros={registros} isMuestra={isMuestra} />
            <Flex gap="middle">
                <Button type="primary" onClick={() => setAbrir(true)}>
                    Crear muestra
                </Button>
                {links.map(({ path, label }) => (
                    <Link key={path} to={path}>
                        <Button type="primary">{label}</Button>
                    </Link>
                ))}
            </Flex>
        </>
    );
};

export default CrearGrupo;
