import { notification, Breadcrumb, Flex, Typography, Card, Table, theme, Button,Input,Form,Select} from "antd"

import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams, Link,useLocation} from "react-router-dom"
import ECharts from 'echarts-for-react';
import ecStat from 'echarts-stat';
import * as echarts from 'echarts';
import API, { BASE_URL } from "../../../API"
import { Header } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { runCMJ } from "../CMJ/Guardar/cmj";
import { runNordic } from "../Nordic/Guardar/nordic";
//import VideoFases from "../../../Componentes/VideoFases";
import SeleccionarMuestra from "../../../Componentes/SeleccionarMuestra";
//import calcularParametros from "../CMJ/Guardar/Rep";
import { FilePdfFilled, DatabaseOutlined} from "@ant-design/icons";
import { CSVLink } from 'react-csv'; // Importar CSVLink
import CrearGrupoModalCuadrante from "./ModalCuadrante";
import CrearGrupoModalCuadranteParams from "./modalCuadranteParams";



const { useToken } = theme;

const PruebaRanking = () => {
    const { token } = useToken()
    const navigate = useNavigate()
    const { id } = useParams()
    const [doc, setDoc] = useState([])
    const [atleta, setAtleta] = useState([])
    var [parametrosT, setParametrosT] = useState(["parametro1","ascendente"])
    const [cargando, setCargando] = useState(false)
    const grafica = useRef(null)
    const [color_area, setColorArea] = useState('whitesmoke')

    const [newfases, setFases] = useState([])
    const [newparametros, setParametros] = useState([])
    const [inicioFases, setInicioFases] = useState(0)
    const [finFases, setFinFases] = useState(0)
    const query = new URLSearchParams(useLocation().search); // Extrae los Query Params
    const extraParam = query.get('extraParam');
    const [recalculando, setreCalculando] = useState(false)
    const [paramsAutomatic, setParamsAutomatic] = useState([true,true])
    const [table, setTable] = useState([[[0,0]]])
    const [abrir,setAbrir] = useState(false);
    const [abrirParams,setAbrirParams] = useState([false,0]);
    const [limitesEjes,setLimitesEjes] = useState([-1500,1500,-1500,1500])
    const [optionsParametros, setOptionsParmetros] = useState([]); 
    const [centersDate, setCentersDate] = useState([0, 0]);
    const optionsParametrosCmj = [
        {
            "nombre": 'Porcentaje fase descarga',
            "label": 'Porcentaje fase descarga',
            "value": "0"
        },
        {
            "nombre": "RSI",
            "label": "RSI",
            "value": "1"
        },
        {
            "nombre": "Tiempo en despegar",
            "label": "Tiempo en despegar",
            "value": "2"
        },
        {
            "nombre": "Fuerza promedio de frenado",
            "label": "Fuerza promedio de frenado",
            "value": "3"
        },
        {
            "nombre": "Fuerza promedio de frenado pierna izq.",
            "label": "Fuerza promedio de frenado pierna izq.",
            "value": "4"
        },
        {
            "nombre": "Fuerza promedio de frenado pierna der.",
            "label": "Fuerza promedio de frenado pierna der.",
            "value": "5"
        },
        {
            "nombre": "Fuerza pico de frenado pierna der.",
            "label": "Fuerza pico de frenado pierna der.",
            "value": "6"
        },
        {
            "nombre": "Fuerza pico de frenado pierna izq.",
            "label": "Fuerza pico de frenado pierna izq.",
            "value": "7"
        },
        {
            "nombre": "Potencia promedio de frenado",
            "label": "Potencia promedio de frenado",
            "value": "8"
        },
        {
            "nombre": "Velocidad promedio de frenado",
            "label": "Velocidad promedio de frenado",
            "value": "9"
        },
        {
            "nombre": "Fuerza promedio de frenado relativa",
            "label": "Fuerza promedio de frenado relativa",
            "value": "10"
        },
        {
            "nombre": "Potencia promedio de frenado relativa",
            "label": "Potencia promedio de frenado relativa",
            "value": "11"
        },
        {
            "nombre": "Impulso de frenado",
            "label": "Impulso de frenado",
            "value": "12"
        },
        {
            "nombre": "Duración frenado",
            "label": "Duración frenado",
            "value": "13"
        },
        {
            "nombre": "% duración frenado",
            "label": "% duración frenado",
            "value": "14"
        },
        {
            "nombre": "RFD",
            "label": "RFD",
            "value": "15"
        },
        {
            "nombre": "Fuerza pico de frenado",
            "label": "Fuerza pico de frenado",
            "value": "16"
        },
        {
            "nombre": "Potencia pico de frenado",
            "label": "Potencia pico de frenado",
            "value": "17"
        },
        {
            "nombre": "Velocidad pico de frenado",
            "label": "Velocidad pico de frenado",
            "value": "18"
        },
        {
            "nombre": "Fuerza pico relativa de frenado",
            "label": "Fuerza pico relativa de frenado",
            "value": "19"
        },
        {
            "nombre": "Potencia pico relativa de frenado",
            "label": "Potencia pico relativa de frenado",
            "value": "20"
        },
        {
            "nombre": "Impulso pico relativa de frenado",
            "label": "Impulso pico relativa de frenado",
            "value": "21"
        },
        {
            "nombre": "Promedio RDF de frenado pierna izq.",
            "label": "Promedio RDF de frenado pierna izq.",
            "value": "22"
        },
        {
            "nombre": "Promedio RDF de frenado pierna der.",
            "label": "Promedio RDF de frenado pierna der.",
            "value": "23"
        },
        {
            "nombre": "Impulso positivo",
            "label": "Impulso positivo",
            "value": "24"
        },
        {
            "nombre": "Profundidad de contramovimiento",
            "label": "Profundidad de contramovimiento",
            "value": "25"
        },
        {
            "nombre": "Fuerza a minimo desplazamiento",
            "label": "Fuerza a minimo desplazamiento",
            "value": "26"
        },
        {
            "nombre": "Relación de impulsos",
            "label": "Relación de impulsos",
            "value": "27"
        },
        {
            "nombre": "Fuerza relativa a minimo desplazamiento",
            "label": "Fuerza relativa a minimo desplazamiento",
            "value": "28"
        },
        {
            "nombre": "Rigidez de contramivimiento",
            "label": "Rigidez de contramivimiento",
            "value": "29"
        },
        {
            "nombre": "Fuerza promedio de propulsión",
            "label": "Fuerza promedio de propulsión",
            "value": "30"
        },
        {
            "nombre": "Fuerza promedio de propulsión izq",
            "label": "Fuerza promedio de propulsión izq",
            "value": "31"
        },
        {
            "nombre": "Fuerza promedio de propulsión der",
            "label": "Fuerza promedio de propulsión der",
            "value": "32"
        },
        {
            "nombre": "Potencia promedio de propulsión",
            "label": "Potencia promedio de propulsión",
            "value": "33"
        },
        {
            "nombre": "Velocidad promedio de propulsión",
            "label": "Velocidad promedio de propulsión",
            "value": "34"
        },
        {
            "nombre": "Fuerza promedio de propulsion relativa",
            "label": "Fuerza promedio de propulsion relativa",
            "value": "35"
        },
        {
            "nombre": "Potencia promedio de propulsión relativa",
            "label": "Potencia promedio de propulsión relativa",
            "value": "36"
        },
        {
            "nombre": "Fuerza pico de propulsión",
            "label": "Fuerza pico de propulsión",
            "value": "37"
        },
        {
            "nombre": "Potencia pico de propulsion",
            "label": "Potencia pico de propulsion",
            "value": "38"
        },
        {
            "nombre": "Fuerza pico relativa de propulsion",
            "label": "Fuerza pico relativa de propulsion",
            "value": "39"
        },
        {
            "nombre": "Potencia pico relativa de propulsion",
            "label": "Potencia pico relativa de propulsion",
            "value": "40"
        },
        {
            "nombre": "Impulso de propulsión",
            "label": "Impulso de propulsión",
            "value": "41"
        },
        {
            "nombre": "Fase propulsion duracion",
            "label": "Fase propulsion duracion",
            "value": "42"
        },
        {
            "nombre": "Fase propulsion duracion",
            "label": "Fase propulsion duracion",
            "value": "43"
        },
        {
            "nombre": "Impulso relativo de propulsión",
            "label": "Impulso relativo de propulsión",
            "value": "44"
        },
        {
            "nombre": "Fuerza promedio de propulsión izq",
            "label": "Fuerza promedio de propulsión izq",
            "value": "45"
        },
        {
            "nombre": "Fuerza promedio de propulsión der",
            "label": "Fuerza promedio de propulsión der",
            "value": "46"
        },
        {
            "nombre": "Fuerza propulsiva der en pico de fuerza combinado",
            "label": "Fuerza propulsiva der en pico de fuerza combinado",
            "value": "47"
        },
        {
            "nombre": "Fuerza propulsiva izq en pico de fuerza combinado",
            "label": "Fuerza propulsiva izq en pico de fuerza combinado",
            "value": "48"
        },
        {
            "nombre": "Velocidad de despegue",
            "label": "Velocidad de despegue",
            "value": "49"
        },
        {
            "nombre": "Altura de salto",
            "label": "Altura de salto",
            "value": "50"
        },
        {
            "nombre": "Momentum de salto",
            "label": "Momentum de salto",
            "value": "51"
        },
        {
            "nombre": "Tiempo de vuelo",
            "label": "Tiempo de vuelo",
            "value": "52"
        },
        {
            "nombre": "Asimetría de fuerza de frenado promedio (Izquierda/derecha)",
            "label": "Asimetría de fuerza de frenado promedio (Izquierda/derecha)",
            "value": "53"
        },
        {
            "nombre": "Asimetría de fuerza de propulsión promedio (Izquierda/Derecha)",
            "label": "Asimetría de fuerza de propulsión promedio (Izquierda/Derecha)",
            "value": "54"
        },
        {
            "nombre": "Asimetría fuerza de frenado pico Izquierda/Derecha",
            "label": "Asimetría fuerza de frenado pico Izquierda/Derecha",
            "value": "55"
        },
        {
            "nombre": "Asimetría de fuerza de propulsión pico Izquierda/Derecha",
            "label": "Asimetría de fuerza de propulsión pico Izquierda/Derecha",
            "value": "56"
        },
        {
            "nombre": "Asimetría de fuerza de propulsión pico Derecha/Izquierda",
            "label": "Asimetría de fuerza de propulsión pico Derecha/Izquierda",
            "value": "57"
        },
        {
            "nombre": "Asimetría de potencia de propulsión pico Derecha/Izquierda",
            "label": "Asimetría de potencia de propulsión pico Derecha/Izquierda",
            "value": "58"
        },
        {
            "nombre": "Asimetría de potencia de propulsión pico Izquierda/Derecha",
            "label": "Asimetría de potencia de propulsión pico Izquierda/Derecha",
            "value": "59"
        },
        {
            "nombre": "Asimetría de impulso de propulsión pico Derecha/Izquierda",
            "label": "Asimetría de impulso de propulsión pico Derecha/Izquierda",
            "value": "60"
        },
        {
            "nombre": "Asimetría de impulso de propulsión pico Izquierda/Derecha",
            "label": "Asimetría de impulso de propulsión pico Izquierda/Derecha",
            "value": "61"
        }
    ]
    const optionsParametrosNordic = [
        {
            "nombre": 'Pico de Fuerza pierna der.',
            "label": 'Pico de Fuerza pierna der.',
            "value": "0"
        },
        {
            "nombre": "Pico de Fuerza pierna izq.",
            "label": "Pico de Fuerza pierna izq.",
            "value": "1"
        },
        {
            "nombre": "Pico de Fuerza pierna der + izq.",
            "label": "Pico de Fuerza pierna der + izq.",
            "value": "2"
        },
        {
            "nombre": "Asimetria der/izq.",
            "label": "Asimetria der/izq.",
            "value": "3"
        },
        {
            "nombre": "Porcentaje Asimetria der/izq.",
            "label": "Porcentaje Asimetria der/izq.",
            "value": "4"
        },
    ]
    const [name, setName] = useState("")
    const [optionsOrder, setOptionsOrder] = useState([
        {
            nombre: 'ascendente',
            label: 'Ascendente',
            value: 'ascendente', // Cambiado a texto claro
        },
        {
            nombre: 'descendente',
            label: 'Descendente',
            value: 'descendente', // Cambiado a texto claro
        },
    ]);
    



    const getData = async (element) => {
        const nombre = extraParam==="prueba" ? "atleta": "Muestra"
        setName(nombre)
        const endpoint = extraParam === "prueba" ? `prueba/${element}` : `muestra/${element}`;
        const data = await API(endpoint);
        return data;
    };
    
    const cargar = async () => {
        try {
            setCargando(true);
    
            const elements = id.split(",");
            const updatedDocs = await Promise.all(
                elements.map(async (element, index) => {
                    const { data } = await getData(element);
                    if(data?.doc.tipoPrueba === "CMJ"){
                        setOptionsParmetros(optionsParametrosCmj); // Usa setState
                    }
                    else{
                        setOptionsParmetros(optionsParametrosNordic);
                    }
                    
    
                    if (extraParam === "prueba") {
                        const parametros = await reCalcularParametros(
                            data?.doc.sistema_de_pesos_y_tiempos,
                            index,
                            data?.doc.tipoPrueba
                        );
                        return {
                            ...data.doc,
                            id: index,
                            parametros,
                        };
                    } else {
                        //console.log(data?.doc)
                        return {
                            ...data?.doc,
                            id: index,
                        };
                    }
                })
            );
    
            setDoc(updatedDocs);
        } catch (err) {
            notification.error({ description: err?.response?.data?.mensaje || err.toString() });
            navigate('/pruebas');
        } finally {
            setCargando(false);
        }
    };
    
    const reCalcularParametros = async (subset1, index, tipoPrueba) => {
        setreCalculando(true);
    
        // Actualiza las opciones según el tipo de prueba
        if (tipoPrueba === "CMJ") {
            setOptionsParmetros(optionsParametrosCmj); // Usa setState
            const { fases: fases_calculadas, parametros: parametros_calculados } = await runCMJ(subset1);
            setParametros([...parametros_calculados]);
            setFases(fases_calculadas);
            setreCalculando(false);
            return parametros_calculados;
        } else {
            setOptionsParmetros(optionsParametrosNordic); // Usa setState
            const { fases: fases_calculadas, parametros: parametros_calculados } = await runNordic(subset1);
            setParametros([...parametros_calculados]);
            setFases(fases_calculadas);
            setreCalculando(false);
            return parametros_calculados;
        }
    };




    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/pruebas">Pruebas realizadas</Link>
        },
        {
            title: <Link to="/ranking">Ranking</Link>
        },
        
    ]
   
    
    

      const guardar = async (payload)=>{
        try{
            setCargando(true)
            //console.log(payload)
            const cuadranteData = []
            doc.forEach((element, index) => {
                cuadranteData.push({
                    "id":index,
                    "atleta":element.atleta,
                    "param1":[parametrosT[0],element.param1],
                    "param2":[parametrosT[1],element.param2],
                    "cuadrante":element.cuadrante
                })
            });
            await API.post('cuadrante',{...payload,cuadranteData})
            notification.success({
                description:'Cuadrante creado correctamente, puedes visualizarlo en la sección Cuadrantes'
            })
            
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString()
            })
            
        }finally{
            setCargando(false)
        }
    }


    const updateData = async () => {
        try {
            setCargando(true);
    
            const newTable = [[]];
            const limites = [0, 0, 0, 0];
            const params = [];
    
            // Calcular los límites antes de actualizar el estado
            doc.forEach((val, index) => {
                params.push([val.param1, val.param2]);
                newTable[0].push([val.param1, val.param2]);
    
                const porcentajeParam1 = (5 * val.param1) / 100;
                const porcentajeParam2 = (5 * val.param2) / 100;
    
                if (val.param1 < limites[0] || limites[0] === 0) {
                    limites[0] = val.param1 - porcentajeParam1;
                }
                if (val.param1 > limites[1] || limites[1] === 0) {
                    limites[1] = val.param1 + porcentajeParam1;
                }
                if (val.param2 < limites[2] || limites[2] === 0) {
                    limites[2] = val.param2 - porcentajeParam2;
                }
                if (val.param2 > limites[3] || limites[3] === 0) {
                    limites[3] = val.param2 + porcentajeParam2;
                }
            });
    
            // Aquí, calculas los límites y cuadrantes antes de actualizar el estado
            let updatedLimites = [...limites];
            //console.log(paramsAutomatic)
            if (paramsAutomatic[0] && paramsAutomatic[1]) {
                // Usas los límites completos si ambos parámetros son automáticos
                updatedLimites = limites;
            
            }else if(!paramsAutomatic[0] && !paramsAutomatic[1]){
                updatedLimites = [limitesEjes[0], limitesEjes[1], limitesEjes[2], limitesEjes[3]];
            } 
            else if (paramsAutomatic[0]) {
                // Si solo el primer parámetro es automático, mantienes el segundo parámetro
                updatedLimites = [limites[0], limites[1], limitesEjes[2], limitesEjes[3]];
            } else if (paramsAutomatic[1]) {
                // Si solo el segundo parámetro es automático, mantienes el primer parámetro
                updatedLimites = [limitesEjes[0], limitesEjes[1], limites[2], limites[3]];
            }
    
            // Calcula los centros de los límites
            const centers = [
                parseFloat(((updatedLimites[0] + updatedLimites[1]) / 2).toFixed(2)),
                parseFloat(((updatedLimites[2] + updatedLimites[3]) / 2).toFixed(2)),
            ];
            setCentersDate(centers);
            //console.log(centers);
    
            // Actualiza el cuadrante de cada parámetro en base a su posición
            newTable[0].forEach((value, index) => {
                if (value[0] > centers[0] && value[1] > centers[1]) {
                    Object.assign(doc[index], { cuadrante: "I" });
                }
                if (value[0] < centers[0] && value[1] > centers[1]) {
                    Object.assign(doc[index], { cuadrante: "II" });
                }
                if (value[0] < centers[0] && value[1] < centers[1]) {
                    Object.assign(doc[index], { cuadrante: "III" });
                }
                if (value[0] > centers[0] && value[1] < centers[1]) {
                    Object.assign(doc[index], { cuadrante: "IV" });
                }
            });
    
            
            setLimitesEjes(updatedLimites);
            setTable(newTable);
    
        } catch (error) {
            console.log(error);
        } finally {
            setCargando(false);
        }
    };
    
    const ordenarLista = (orden) => {
        const docOrdenado = [...doc].sort((a, b) => {
            const valorA = a.param1 || 0; // Asegúrate de que `param1` esté definido
            const valorB = b.param1 || 0;
    
            return orden === 'ascendente' ? valorA - valorB : valorB - valorA;
        });
    
        // Asignar el puesto basado en el nuevo orden
        const docConPuestos = docOrdenado.map((item, index) => ({
            ...item,
            puesto: index + 1, // El puesto es el índice + 1
        }));
        //console.log(docConPuestos)
        setDoc(docConPuestos); // Actualiza el estado con la lista ordenada y los puestos
    };
    
    
    
        
    const handleChangep1 = async (value) => {
        const parametroSeleccionado = optionsParametros[value].nombre;
        const ordenActual = parametrosT[1]; // Asegúrate de que contiene "ascendente" o "descendente"
    
        setParametrosT([parametroSeleccionado, ordenActual]);
    
        try {
            setCargando(true);
    
            // Actualiza los valores de param1 en la lista
            for (let i = 0; i < doc.length; i++) {
                doc[i].parametros.forEach((val) => {
                    if (val.nombre === parametroSeleccionado) {
                        Object.assign(doc[i], { param1: val.valor });
                    }
                });
            }
    
            // Ordena la lista según el orden actual
            ordenarLista(ordenActual); 
        } catch (error) {
            console.error(error);
        } finally {
            setCargando(false);
        }
    };
    
      const handleChangep2 = async (value) => {
    try {
        setCargando(true);
        setParametrosT([parametrosT[0], value]);
        ordenarLista(value); // Llama a la función de ordenamiento con el valor seleccionado
    } catch (error) {
        console.log(error);
    } finally {
        setCargando(false);
    }
      };
    
    
    
   


    useEffect(() => {
        cargar()
        
    }, [])

    



    return <div >
        <div style={{ padding: 32 }}>
            <Breadcrumb items={miga} />
        </div>
        <Typography.Title style={{ textAlign: 'center' }}>{"Rankings" || ''} </Typography.Title>
         <br></br>
         <Form>

            <Flex gap="middle" justify="center">
            <Form.Item name={'Parametro'} label="Parametro">
                <Select 
                    allowClear 
                    style={{ width: 450 }} 
                    options={optionsParametros} 
                    onChange={handleChangep1}
                />
            </Form.Item>
            <Form.Item name={'Orden'} label="Orden">
                <Select 
                    allowClear 
                    style={{ width: 250 }} 
                    options={optionsOrder} 
                    onChange={handleChangep2}
                    defaultValue={parametrosT[1]}
                    
                />
            </Form.Item>

            </Flex> 


         </Form>
           
        <div style={{ padding: 32 }}>
         <br></br>
            <Flex gap="middle" justify="space-evenly">
            <Table 
                rowKey={'id'} 
                loading={cargando} 
                dataSource={doc} 
                pagination={false} 
                style={{ width: 1500 }}
            >
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>Puesto</div>} 
                    dataIndex={"puesto"} 
                    width="20%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>Atleta</div>} 
                    dataIndex={"nombre"} 
                    width="20%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>{parametrosT[0]}</div>} 
                    dataIndex={"param1"} 
                    width="20%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
                
            </Table>


            </Flex>
            
            <br></br>
          



        </div>
    </div>
}
export default PruebaRanking