import { Breadcrumb, Button,Input, DatePicker, Dropdown, Flex,Select, Form, Popconfirm, Table , notification, Typography,Space} from "antd"
import { ClockCircleOutlined, DeleteOutlined, DownOutlined, EditOutlined, EyeFilled, FilterOutlined, PlusOutlined, UserAddOutlined, UserOutlined,DiffFilled } from '@ant-design/icons';
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import API from "../../../API";
import dayjs from "dayjs";
import CrearGrupo from "./Componentes/CrearGrupo";
import ValidarRol from "../../../Componentes/ValidarRol";
import { useForm } from "antd/es/form/Form";
import useSesion from "../../../Hooks/Sesion";

const PruebaListado = () => {

    const { filtro_prueba, setFiltroPrueba } = useSesion()
    const navigate = useNavigate()
    const [docs, setDocs] = useState([])
    const [muestras,setMuestras] = useState([])
    const [fechaU, setFechaU] = useState([0,0])
    const [seleccionados, setSeleccionados] = useState([])
    const [muestrasSeleccionados, setMuestrasSeleccionadas] = useState([])
    const [instituciones, setInstituciones] = useState([])
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: 'Pruebas realizadas'
        }
    ]

    const cargarMuestras = async () => {
        try {
            setCargando(true)
            const { data } = await API('muestra/all')
            setMuestras(data.docs)
            //console.log(data.docs)
        } catch (err) {
            notification.error({ description: err?.response?.data?.mensaje || err.toString() })
        } finally {
            setCargando(false)
        }
    }

    const cargar = async (payload) => {
        cargarMuestras()
        setFiltroPrueba(prev => ({ ...prev, fecha: payload.fecha }))
        try {
            const params = {
                fi: payload.fecha[0].toDate(),
                ff: payload.fecha[1].toDate(),
                mm: payload.momento,
                tp: payload.tipoPrueba,
                in: payload.institucion,
                at: payload.atleta
            }
            //console.log(payload.institucion)
            setCargando(true)
            const { data } = await API('prueba', { params })
            setDocs(data.docs)
            
            //console.log(data)
        } catch (err) {

        } finally {
            setCargando(false)
        }
    }
    const cargarFecha = async () =>{
            try {
                const params = {
                    fi: dayjs("2020-01-01", "YYYY-MM-DD").toDate(),
                    ff: dayjs().toDate(),
                };
                
                const { data } = await API('prueba',{params})
                fechaU[1] = data.docs[0].updatedAt.split("T")[0] 
                for (let doc of data.docs) {
                    if(doc.updatedAt.split("T")[0] !== fechaU[1]){
                        fechaU[0] = doc.updatedAt.split("T")[0]
                        setFechaU(fechaU)
                        break
                    }
                
                    
                }
                
                //console.log(fechaU);
                
                setFiltroPrueba(prev => ({ ...prev, fecha: [dayjs(fechaU[0], "YYYY-MM-DD"), dayjs(fechaU[1], "YYYY-MM-DD")] }))
                form.setFieldsValue({
                        fecha: [dayjs(fechaU[0], "YYYY-MM-DD"), dayjs(fechaU[1], "YYYY-MM-DD").add(1, "day")]
                })
              
        
                
                form.submit()
                
                //console.log(data)
            } catch (err) {
                console.log("no sirve")
            } finally {
                setCargando(false)
            }
    }

    const cargarIns = async () => {
        try {
            const { data } = await API(`institucion`)
            setInstituciones(data.docs)
        } catch (error) {
            notification.error({ description: error.response.data.mensaje })
            navigate('/atletas')
        }
    }


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSeleccionados([...selectedRows.map(s => s._id)])
        }
    };
    const rowSelectionMuestras = {
        onChange: (selectedRowKeys, selectedRows) => {
            setMuestrasSeleccionadas([...selectedRows.map(s => s._id)])
        }
    };

    const borrar=async (prueba)=>{
        try{
            setCargando(true)
            await API.delete(`prueba/${prueba._id}`)
            cargar(filtro_prueba)
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Prueba no borrada'
            })
        }finally{
            setCargando(false)
        }
    }
    const borrarMuestra=async (muestra)=>{
        try{
            setCargando(true)
            await API.delete(`muestra/${muestra._id}`)
            cargar(filtro_prueba)
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Prueba no borrada'
            })
        }finally{
            setCargando(false)
        }
    }
   
    useEffect(() => {
        cargarIns()
        
        cargarFecha()
        

    }, [])

    return <div style={{ padding: 32 }}>
        <Breadcrumb items={miga} />

        <br></br>
        <Form form={form} layout="vertical" onFinish={cargar}>

            <Flex gap="middle">
                <Form.Item name="fecha" label="Rango de fecha" rules={[{ required: false, message: 'Ingrese un rango de fecha' }]}>
                    <DatePicker.RangePicker />
                </Form.Item>
                <Form.Item name="tipoPrueba" label="Tipo de Prueba" >
                    <Select allowClear style={{width:200}} options={[{ label: 'CMJ', value: 'CMJ' },  { label: 'Nordic', value: 'Nordic' }]} />
                </Form.Item>
                <Form.Item name="momento" label="Momento" >
                    <Select allowClear style={{width:200}} options={[{ label: 'Único', value: 'Único' }, { label: 'Pre', value: 'Pre' }, { label: 'Post', value: 'Post' }, { label: '24H', value: '24H' }, { label: '48H', value: '48H' }, { label: '72H', value: '72H' }, { label: '96H', value: '96H' }]} />
                </Form.Item>
                <Form.Item name={'atleta'} label="Atleta" >
                    <Input allowClear style={{width:'100%'}} />
                </Form.Item>
                <Form.Item name={'institucion'} label="Institución" >
                    <Select style={{width:200}} allowClear options={instituciones.map(i => ({ value: i._id, label: i.nombre }))} />
                </Form.Item>
            </Flex>
            <Flex width="100%" gap="" justify="space-between"> 
                <Button loading={cargando} type="primary" htmlType="submit" icon={<FilterOutlined />}>Filtrar</Button>
                
                
            </Flex>
            
        </Form>
        <br></br>
        <Space direction="vertical" style={{ width: '100%' }} align="center">
            <Typography.Title level={1} style={{ fontSize: '28px', fontWeight: 'bold', textAlign: 'center' }}>
                Pruebas
            </Typography.Title>
        </Space>
        <Table 
            rowKey={'_id'} 
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }} 
            loading={cargando} 
            dataSource={docs} 
            pagination={false}
            scroll={{ x: 'max-content', y: 300 }} // Desplazamiento horizontal y vertical
        >
            <Table.Column title="Fecha" dataIndex={'createdAt'} render={(v) => dayjs(v).format("YYYY/MM/DD hh:mm a")} width="20%"/>
            <Table.Column title="Tipo" dataIndex={'tipoPrueba'} width="20%"/>
            <Table.Column title="Momento" dataIndex={'momento'} width="20%"/>
            <Table.Column title="Identificación" dataIndex={'atleta'} render={(d) => d?.ide} width="20%"/>
            <Table.Column title="Atleta" dataIndex={'atleta'} render={(a) => a?.nombre} width="30%"/>
            <Table.Column title="Acciones" render={(d) => (
                <Flex gap={'middle'}>
                    <Link to={`/prueba/${d._id}`}><Button shape="circle" icon={<EyeFilled />} /></Link>
                    <Popconfirm title="No podrás recuperar los datos de esta prueba" onConfirm={() => borrar(d)}>
                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Flex>
            )} />
        </Table>
        <br></br>
       
        <CrearGrupo registros={seleccionados} isMuestra={false} cargar={[cargar,cargarFecha]}/>
        <br></br>
        <br></br>
        
        <Space direction="vertical" style={{ width: '100%' }} align="center">
            <Typography.Title level={1} style={{ fontSize: '28px', fontWeight: 'bold', textAlign: 'center' }}>
                Muestras
            </Typography.Title>
        </Space>
        <Table 
            rowKey={'_id'} 
            rowSelection={{
                type: 'checkbox',
                ...rowSelectionMuestras,
            }} 
            loading={cargando} 
            dataSource={muestras} 
            pagination={false}
            scroll={{ x: 'max-content', y: 300 }} // Desplazamiento horizontal y vertical
        >
             <Table.Column title="Fecha" dataIndex={'createdAt'} render={(v) => dayjs(v).format("YYYY/MM/DD hh:mm a")} width="20%"/>
            <Table.Column title="Tipo" dataIndex={'tipoPrueba'} width="30%"/>
            <Table.Column title="Nombre" dataIndex={'nombre'}  width="20%"/>
            
            
            <Table.Column title="Accione" render={(m) => (
                <Flex gap={'middle'}>
                    <Link to={`/muestra/${m._id}`}><Button shape="circle" icon={<EyeFilled />} /></Link>
                    <Popconfirm title="No podrás recuperar los datos de esta prueba" onConfirm={() => borrarMuestra(m)}>
                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Flex>
            )} />
        </Table>
        <br></br>
        <CrearGrupo registros={muestrasSeleccionados} isMuestra  />
        
    </div>
}

export default PruebaListado