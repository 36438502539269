import { Button, Form, Input, Modal, Typography, notification,Select } from "antd"
import { useState } from "react"
import API from "../../../../../API"

const CrearGrupoModal=({abrir,cerrar,registros, isMuestra})=>{
    const [cargando,setCargando] = useState(false)
    //console.log(isMuestra)
    const guardar = async (payload)=>{
        try{
            setCargando(true)
            
            //console.log(payload)
            //console.log(registros)
            let data = {}
            if(isMuestra){
                 data = await API.post('muestra/muestraSave',{...payload,registros})
                 //console.log(registros)
            }
            else{
                 data = await API.post('muestra',{...payload,registros})
            }
            
            //console.log(data.data.doc)
            if(data.data.doc === 'no se puede hacer una muestra sin parametros'){
                notification.error({
                    description:data.data.doc
                })
            }
            else{
                notification.success({
                    description:data.data.doc
                })
            }
            
            cerrar(false)
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString()
            })
            
        }finally{
            setCargando(false)
            window.location.reload();
        }
    }

    return <Modal open={abrir} closable={!cargando} footer={[]} onCancel={()=>cerrar(false)} title="Crear muestra">
        <Typography.Paragraph>Pruebas seleccionadas {registros.length}</Typography.Paragraph>
        <Form disabled={cargando} layout="vertical" onFinish={guardar}>
        <Form.Item label="Nombre" name="nombre" rules={[{required:true,message:'Ingrese un nombre para la muestra'}]}>
            <Input/>
        </Form.Item>
        <Form.Item name="tipoPrueba" label="Tipo de Prueba" >
                    <Select allowClear style={{width:200}} options={[{ label: 'CMJ', value: 'CMJ' },  { label: 'Nordic', value: 'Nordic' }]} />
        </Form.Item>

        <Form.Item label="Nota" name="nota" rules={[{required:true,message:'Ingrese una nota para la muestra'}]}>
            <Input/>
        </Form.Item>


        <Button loading={cargando} type="primary" htmlType="submit">Crear muestra</Button>
        </Form>
        
        
    </Modal>
}

export default CrearGrupoModal